import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./screens/Dashboard";
import Signin from "./screens/Signin";
import Signup from "./screens/Signup";
import Managemovies from "./screens/Managemovies";
import Editgenres from "./screens/Editgenres";
import Manageusers from "./screens/Manageusers";
import Editmovies from "./screens/Editmovies";

function App() {
  return (
    <div className="App">
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/managemovies" element={<Managemovies />} />
            <Route path="/manageusers" element={<Manageusers />} />
            <Route path="/editgenres" element={<Editgenres />} />
            <Route path="/editmovies/:id" element={<Editmovies /> } />
          </Routes>
        </BrowserRouter>
      </>
    </div>
  );
}

export default App;
