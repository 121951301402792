import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

function Signupformfield() {



  console.log( sessionStorage.getItem("userid"))


  const { addToast } = useToasts();
  const navigate = useNavigate();

  const [isLoading, setisLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmpass] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    setisLoading(true);

    const details = {
      username: username,
      email: email,
      password: password
    };

    console.log(details)

    const url = `https://otcfinanceuk.com/vedia/api/users/registeradmin`;

    axios
      .post(url, details)
      .then((response) => {
        if (response.status === 200 && response.data.result != "") {
          addToast("Registration Successfull", { appearance: "success" });

          localStorage.setItem("data", JSON.stringify(response.data.result))
          setTimeout(() => {
            navigate("/");
          }, 5000);
      
        } else {
          addToast(response.data.error, { appearance: "error" });
          setisLoading(false);
        }
      })
      .catch((error) => {
        if(error.response.status === 422){
          addToast("email already exist", { appearance: "error" });
          setisLoading(false);
        }

        console.log(error);
      });
  };

  return (
    <div className="flex pt-[30px]">
      
      <form onSubmit={handleLogin} className="rounded  mb-4 md:w-[482px] w-full  pr-[30px]  md:ml-[70px] ">
       
     

        <div className="mb-3 py-3">
          <input
            required
            className="shadow appearance-none border-1 rounded-[15px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-[56px]"
            id="username"
            type="text"
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>

   

        <div className="mb-3">
          <input
            className="h-[56px] shadow appearance-none border-1 rounded-[15px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

   

        <div className="mb-3">
          <input
            className="h-[56px] shadow appearance-none border-1 rounded-[15px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <input
            className="h-[56px] shadow appearance-none border-1 rounded-[15px] w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="comfirmpassword"
            type="password"
            placeholder=" Confirm Password"
            onChange={(e) => setConfirmpass(e.target.value)}
            required
          />
        </div>

        {isLoading ? (
          <Loading />
        ) : (
          <div className="flex items-center justify-between pt-[30px]">
            <input
              class="w-full rounded-[15px] border-1 h-[56px] text-white  bg-[#1C85C0] hover:bg-[#1C85C0] hover:text-white"
              type="submit"
  
              value=" Sign up"
            />
          </div>
        )}
      </form>
    </div>
  );
}

export default Signupformfield;
