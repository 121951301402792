import {  useState } from "react";
// import { AuthContext } from "../authContext/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Loading from "./Loading";

import { useToasts } from "react-toast-notifications";

function Signinformfield() {
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const [isLoading, setisLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
//   const { dispatch } = useContext(AuthContext);

  const handleLogin = (e) => {

    e.preventDefault();
    setisLoading(true)
    const details = {
      email: email,
      password: password,
    };

    const url = `https://otcfinanceuk.com/vedia/api/users/signinadmin`;

    axios
      .post(url, details)
      .then((response) => {
        if (response.status === 200 && response.data.result != "") {
        //   dispatch(loginSuccess(response.data.result));

          setTimeout(() => {
            navigate("/dashboard");
          }, 5000);
        } else {
          addToast(response.data.error, { appearance: "error" });
          setisLoading(false);
        }
      })
      .catch((error) => {

        if(error.response.status === 401){
          addToast("Invalid email and password", { appearance: "error" });
          setisLoading(false);
        }

        console.log(error);

        setisLoading(false);
      });
  };

  return (
    <div className="flex pt-[-50px] ">
      <form
        onSubmit={handleLogin}
        className="rounded   mb-4 w-full md:w-[482px]    md:ml-[70px] "
      >
        <div className="mb-3 w-full flex">
          <input
            className="bg-[#D9D9D9] shadow appearance-none border-1 rounded-[15px] w-full py-2 px-3 text-[rgba(44, 43, 43, 0.5)] leading-tight focus:outline-none focus:shadow-outline h-[52px]"
            id="email"
            type="email"
            placeholder="Entet Email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <input
            className="h-[52px] bg-[#D9D9D9] shadow appearance-none border-1 rounded-[15px] w-full py-2 px-3 text-[rgba(44, 43, 43, 0.5)] leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="text"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {isLoading ? (
          <Loading />
        ) : (
          <div className="flex items-center justify-between pt-[30px]">
            <input
              className="w-full rounded-[15px] border-1 h-[56px] text-white  bg-[#1C85C0] hover:bg-[#1C85C0] hover:text-white "
              type="submit"
              value=" Sign in"
            />
          </div>
        )}
      </form>
      

    </div>
  );
}

export default Signinformfield;
