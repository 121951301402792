import React from "react";
import Logo from "../asset/logo.png";


function Navbar() {
  
    return (
      <div className="w-screen h-[62px] z-10  dropdown-shadow-lg pt-[60px] ">
        <div className=" px-2 flex justify-center  items-center w-full h-full">
         
          <div className="flex items-center px-4 py-4">
            <img src={Logo} alt="logo"  />
          </div>
  
          </div>
      </div>
    );
  }
  
export default Navbar