import React from "react";
// import { Link } from "react-router-dom";
// import Signinimage from "../asset/signinimage.png";
// import Formbottom from "../component/Formbottom";
// import Formtop from "../component/Formtop";
import Navbar from "../components/Navbar";
import Signinformfield from "../components/Signinformfield";
import { Link } from "react-router-dom"
// import Signinback from "../component/Signinback";
// import Signinformfield from "../component/Signinformfield";

function Signin() {
  return (
    
    <>
     <div className="bg-[#2C2B2B] w-full h-[100px]">
        <Navbar />
   
      </div>
      <div className="flex flex-col bg-[#2C2B2B] w-full h-screen items-center justify-center ">
     
      <div className="pt-[-100px] md:ml-[-300px]">
      <h2 className="text-[32px]  text-[#cccccc]">Signin</h2>
  
    </div>
     
      <Signinformfield />
   
      <div className="pt-[10px] md:ml-[70px]">
    
      <p className="text-[18px] font-[400] text-[#1C85C0]">
        Don’t have an account?{" "}
        <font className="text-[18px] font-[400] text-[#cccccc]">
          {" "}
          <Link to="/signup">Register</Link>
        </font>
      </p>

   
    </div>
      </div>


 

    </>
     


  )
}

export default Signin