import React, { useState , useEffect} from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../asset/logo.png";
import Loading from "../components/Loading";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

// import Buy from "../asset/buy.png";
// import Sell from "../asset/sell.png";
// import Transfer from "../asset/transfer.png";
// import Wallets from "../asset/wallet.png";

// import { AuthContext } from "../authContext/AuthContext";

// import { logout } from "../authContext/AuthActions";

function Managemovies() {
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const [isLoading, setisLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [confirm_password, setConfirmpass] = useState("");
  
  const [ moviedata, setMoviedata] = useState([]);

  console.log(moviedata)
  //   const {dispatch} = useContext(AuthContext);

  //   const [copySuccess, setCopySuccess] = useState("");



  const handleLogout = () => {
    // dispatch(logout())

    navigate("/signin");
  };
  const [nav, setNav] = useState(false);

  const handleClick = () => setNav(!nav);

  // console.log(user);

  //   const refer = `https://2geda.ng/signup/${user.my_ref_code}`;


  const  fetchGenre = () => {
   

    const url = `https://otcfinanceuk.com/vedia/api/movies/allmovie`;

    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
         
            setMoviedata(response.data)
            
 fetchGenre()
         
        } 
      })
      .catch((error) => {
        console.log(error);
        setisLoading(false);
      });




  }




useEffect(()=>{

 fetchGenre()
 },[])

 
 const handleDelete = (id) => {


  console.log(id)
  const url = `https://otcfinanceuk.com/vedia/api/movies/deletemovie/${id}`;

  axios
    .delete(url)
    .then((response) => {
      if (response.status === 200) {
        addToast("Deleted  Successfully", { appearance: "success" });

      
      } else {
        addToast(response.data.error, { appearance: "error" });
 
      }
    })
    .catch((error) => {
      addToast("Genre Does not exist", { appearance: "success" });

      console.log(error);

    });
};

  return (
    <div className="bg-[#1E1E1E] h-auto w-full">
      <div className="w-full h-full bg-[#1E1E1E] flex justify-center items-center  justify-between">
        <div
          className={
            !nav
              ? "hidden w-[255px] h-auto bg-[#1E1E1E] md:flex md:flex-col justify-between mt-[-330px] "
              : "mt-[-1350px] md:mt-[0px]  w-[255px] h-auto bg-[#1E1E1E] md:flex flex-col"
          }
        >
          <div className="flex items-center  h-[100px] w-[150px] px-6">
            <img src={Logo} alt="logo" className="w-[150px] h-[45px]" />
          </div>

          <div className="  h-auto w-[250px]">
            <ul>
              <li className="text-[16px] text-[#ffffff] flex">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-[#FFFBF5] text-opacity-50"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5"
                    />
                  </svg>
                </span>

                <span className="ml-[20px] text-[#FFFBF5] text-opacity-50 text-[16px+]">
                  {" "}
                  <Link to="/dashboard"> ADD MOVIE</Link>
                </span>
              </li>
              <li className="text-[16px] text-[#ffffff] flex">
                {" "}
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-[#FFFBF5] text-opacity-50"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                    />
                  </svg>
                </span>
                <span className="ml-[20px] text-[#FFFBF5] text-opacity-50">
                <Link to="/managemovies" >MANAGE MOVIES</Link>  
                </span>
              </li>
              <li className="text-[16px] text-[#ffffff] flex">
                {" "}
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6  text-[#FFFBF5] text-opacity-50"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                    />
                  </svg>
                </span>
                <span className="ml-[20px] text-[#FFFBF5] text-opacity-50">
                  {" "}
                  <Link to="/editgenres">EDIT GENRES</Link>  
                </span>
              </li>
              <li className="text-[16px] text-[#ffffff] flex ">
                {" "}
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-[#FFFBF5] text-opacity-50"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 013.75 9.375v-4.5zM3.75 14.625c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5a1.125 1.125 0 01-1.125-1.125v-4.5zM13.5 4.875c0-.621.504-1.125 1.125-1.125h4.5c.621 0 1.125.504 1.125 1.125v4.5c0 .621-.504 1.125-1.125 1.125h-4.5A1.125 1.125 0 0113.5 9.375v-4.5z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 6.75h.75v.75h-.75v-.75zM6.75 16.5h.75v.75h-.75v-.75zM16.5 6.75h.75v.75h-.75v-.75zM13.5 13.5h.75v.75h-.75v-.75zM13.5 19.5h.75v.75h-.75v-.75zM19.5 13.5h.75v.75h-.75v-.75zM19.5 19.5h.75v.75h-.75v-.75zM16.5 16.5h.75v.75h-.75v-.75z"
                    />
                  </svg>
                </span>
                <span className="ml-[20px] text-[#FFFBF5] text-opacity-50">
                  {" "}
                  <Link to="/manageusers">MANAGE USERS</Link>  
                </span>
              </li>
              <li className="text-[16px] text-[#ffffff] flex">
                {" "}
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-[#FFFBF5] text-opacity-50"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                </span>
                <span className="ml-[20px] text-[#FFFBF5] text-opacity-50">
                  {" "}
                  Profile Settings
                </span>
              </li>

              <li className="text-[16px] text-[#ffffff] flex mt-[100px]">
                {" "}
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                    />
                  </svg>
                </span>
                <span className="ml-[20px]" onClick={handleLogout}>
                  {" "}
                  Log out
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div className="md:flex w-full h-auto mt-[-250px] md:mt-[-425px] bg-[#2c2b2b] pt-[50px]">
          <div className="w-full mt-[150px]  md:mt-[375px] h-[150px] md:h-[62px]  fixed bg-[#000000] md:bg-[#000000] px-4 pt-[50px] md:pt-[30px] pb-[30px] ">
            <div className=" px-2 flex justify-between  items-center w-full h-full md:pr-[300px]">
              <div className="flex flex-col justify-between items-center pt-[30px] pb-[20px]">
                {/* <h2 className="text-[16px] font-[500]">Welcome {user.username.toUpperCase()} </h2> */}

                <div className="md:hidden px-4 py-4">
                  {!nav ? (
                    <svg
                      onClick={handleClick}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                      />
                    </svg>
                  ) : (
                    <svg
                      onClick={handleClick}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  )}
                </div>
              </div>

              <div className="flex  gap-1 pt-[20px] pb-[20px]">
                <div className=" ">

                <svg xmlns="http://www.w3.org/2000/svg" fill="#1C85C0" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-[#1C85C0]">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
</svg>

                </div>

                <div className="pl-[10px]">
                  <h2 className="text-[12px] font-[500] text-[#1C85C0]">User Id </h2>
                  {/* <h2 className="text-[16px] font-[500]">{user.username.toUpperCase()} </h2> */}
                </div>
              </div>
            </div>
          </div>

          <div className=" w-full mt-[-150px] md:w-[800px] md:mt-[-0px] md:pl-[40px] pt-[180px] md:flex md:flex-col justify-center items-center">
            <div className="w-full pl-[20px] pr-[30px]  ">
              <div
               
                className="rounded  mb-4 md:w-[620px] w-full    mt-[300px] bg-[#cccccc]  px-[50px] py-[50px] rounded-[10px]"
              >
                <div className="mb-3 py-3 flex justify-between">
                  <label className="w-[50%]">Search Movie</label>
                  <input
                    required
                    className="shadow appearance-none border-1 rounded-[10px] w-full py-2 px-3 text-white  bg-[#000000] leading-tight focus:outline-none focus:shadow-outline h-[45px] "
                    id="username"
                    type="text"
                    placeholder="Magic Mike"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>

{

  moviedata?.map((item)=>(

<div className=" w-full h-full  flex  justify-between mt-[50px]">

<div className="flex  justify-evenly bg-[#ffffff] w-[90%] rounded-[5px]">
    <p className="text-[20px]">{item.title}</p>
    <p className="text-[20px]">{item.year}</p>
    <p className="text-[20px]"> { item.isSeries === true ? item.isSeries : "Movies" }</p>
    <p className="text-[20px]"> {item.genre}</p>

    

</div>
<div className="flex  justify-evenly">

<svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#1C85C0" className="w-6 h-6" onClick={ () => handleDelete(item._id)}>
  <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
</svg>

<Link to={'/editmovies/'+item._id}> 
<svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#1C85C0" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
</svg>
</Link>

    

</div>

</div>

  ))
}


              </div>
            </div>

            <div className="grid grid-cols-1 gap-2  md:grid-cols-2 pl-[20px] pr-[20px]  pt-[30px] flex justify-center items-center md:max-w-[1200px] w-full ">
        
            <div className="md:w-[341px] w-full h-[205px] bg-[#1C85C0] rounded mt-[0px] px-4 py-2 pb-[50px]  ">
              <h2 className="text-[18px] font-[600] text-[#ffffff] mt-[10px]">
            ACTIVE USERS
              </h2>
              <div class="w-full md:w-full px-3 mb-6 md:mb-0 mt-[10px] items-center justify-center">
               
              
                 

                 <h1 className="text-center text-[78px] text-[#ffffff]">33000</h1>
             
              </div>
            </div>

         
            <div className="md:w-[256px]   w-full  h-[205px] bg-[#D9D9D9] rounded mt-[0px] px-4 py-2 pb-[50px]  ">
              <h2 className="text-[18px] font-[600] text-[#C01C26] mt-[10px]">
              BLOCKED USERS
              </h2>
              <div class="w-full md:w-full px-3 mb-6 md:mb-0 mt-[10px] items-center justify-center">
               
              
                 

                 <h1 className="text-center text-[78px] text-[#C01C26]">600</h1>
             
              </div>
            </div>

         
            </div>

            <div
              class="overflow-x-auto relative shadow-md sm:rounded-lg mt-[50px]
              bg-[#ffffff]n w-full hidden md:flex"
            ></div>
          </div>

          <div className="md:w-[300px]  pr-[20px] pl-[20px] pb-[50px] md:mt-[400px]">
            <div className="w-full h-[350px] bg-white rounded mt-[90px] px-4 py-2 pb-[50px]  ">
              <h2 className="text-[18px] font-[600] text-[#0E6BC3] mt-[10px]">
              USERS
              </h2>
              <div class="w-full md:w-full px-3 mb-6 md:mb-0 mt-[10px] items-center justify-center">
               
              
                 

                 <h1 className="items-center text-[78px] text-[#1C85C0]">33000</h1>
             
              </div>
            </div>


            <div className="w-full h-[350px] bg-black rounded md:mt-[160px] px-4 py-2 pb-[50px]   mt-[50px]">
              <h2 className="text-[18px] font-[600] text-[#ffffff] mt-[10px]">
              MOVIES
              </h2>
              <div class="w-full md:w-full px-3 mb-6 md:mb-0 mt-[10px] items-center justify-center">
               
              
                 

                 <h1 className="items-center text-[78px] text-[#ffffff]">33000</h1>
             
              </div>
            </div>

  





          </div>







        </div>
      </div>
    </div>
  );
}

export default Managemovies;
